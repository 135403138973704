<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FDEAE8" />
    <path
      d="M16 17C16 15.8954 16.8954 15 18 15H25V19C25 20.6569 26.3431 22 28 22H32V26H27C25.8954 26 25 26.8954 25 28V33H18C16.8954 33 16 32.1046 16 31V17Z"
      fill="#B82025"
    />
    <path
      d="M27 19V15.083C29.5125 15.5045 31.4955 17.4875 31.917 20H28C27.4477 20 27 19.5523 27 19Z"
      fill="#B82025"
    />
    <path
      d="M27 31C27 30.4477 27.4477 30 28 30H29V29C29 28.4477 29.4477 28 30 28C30.5523 28 31 28.4477 31 29V30H32C32.5523 30 33 30.4477 33 31C33 31.5523 32.5523 32 32 32H31V33C31 33.5523 30.5523 34 30 34C29.4477 34 29 33.5523 29 33V32H28C27.4477 32 27 31.5523 27 31Z"
      fill="#B82025"
    />
  </svg>
</template>
